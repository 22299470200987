import MainComponent from "./Components/mainComponent";

function App() {
  return (
    <div className="App">
      <MainComponent/>
    </div>
  );
}

export default App;
