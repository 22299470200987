import React, {useEffect, useState} from 'react';
import germanAPI from "../../api/germanAPI";
import WordsTraining from "../../wordsTraining/wordsTraining";
import Menu from "../../UI/menu/menu";


const GermanWords = () => {
    const [WordsFromServer, setWordsFromServer]=useState([]);
    const [UsingWords, setUsingWords]=useState([]);
    const [Levels, setLevels]=useState(null);
    const [Level, setLevel]=useState('all');
    const [isLoading, setIsLoading]=useState(false)
    async function getLevels(){
        const res=await germanAPI.getLevels();
        let temp=res.data.map(element=>element.level).sort();
        temp.push("all")
        setLevels(temp)
    }
    function changeLevel(Level){
        if(Level==="all") {
            setUsingWords(WordsFromServer);
            return;
        }
        setUsingWords(WordsFromServer.filter(element=>element.level === Level))
    }


    useEffect(() => {
        getLevels();
        germanAPI.getAllGermanWords()
            .then(({data})=>{
                setWordsFromServer(data);
                setUsingWords(data);
                setIsLoading(true)
            })

    }, []);
    useEffect(() => {
        if(Level)
            changeLevel(Level)
    }, [Level]);

    console.log(UsingWords)

    if(isLoading)
    return (
        <div>
            <Menu
                items={Levels}
                item={Level}
                setItem={setLevel}
            />
            <WordsTraining
                WordsArray={UsingWords}
            />
        </div>
    );
};

export default GermanWords;