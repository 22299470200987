import axios from "axios";
import serverConstants from "./serverConstants";


export default class germanAPI{
    static url = this.checkUrl();
    static login = serverConstants.login;
    static psw = serverConstants.psw;

    static checkUrl(){
        let LocalUrl="http://127.0.0.1:8000/germanWordsAPI.php"
        let Url = serverConstants.globalURL+'germanWordsAPI.php';
        if(window.location.href.indexOf('localhost')!==-1) return LocalUrl;
        return Url;
    }

    static async getLevels(){
        const params={
            action: 'getLevels',
            login:this.login,
            password:this.psw
        };
        const responce = await axios.get(this.url, {params});
        return responce;
    }
      static async getAllGermanWords(){
        const params={
            action: 'getAllGermanWords',
            login:this.login,
            password:this.psw
        };
        const responce = await axios.get(this.url, {params});
        return responce;
    }
}