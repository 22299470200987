import React, {useState} from 'react';
import EnglishWords from "./english/words/englishWords";
import GermanWords from "./german/words/germanWords";
import Menu from "./UI/menu/menu";

const MainComponent = () => {
    const [language, setLanguage]=useState();
    const languages= {
        "English":<EnglishWords/>,
        "German":<GermanWords/>
    };
    return (
        <div>
            <Menu
                items={Object.keys(languages)}
                setItem={setLanguage}
                item={language}
            />
            {languages[language]}
        </div>
    );
};

export default MainComponent;