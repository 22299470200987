import React, {useEffect, useState} from 'react';
import cl from "./wordsTraining.module.css"

const WordsTraining = ({WordsArray}) => {
    const [UsingWordsArray, setUsingWordsArray]=useState(WordsArray)
    const [UsingWord, setUsingWord]=useState({})
    const [Loading, setLoading]=useState(false)
    const [Answers, setAnswers]=useState()
    const [CorrectAnswer, setCorrectAnswer]=useState()
    const [Information, setInformation]=useState("")
    const [Reload, setReload]=useState(true)
    const [Way, setWay]=useState(0)  //0 - from German to Russian, 1 - from Russian to German
    function checkAnswer(event){
        let answer;
        if(Way==1)
            answer=CorrectAnswer.wordInGerman
        else
            answer=CorrectAnswer.wordInRussian

        if(answer === event.currentTarget.id){
            setInformation("Your answer is correct.")
            setWay(Math.round(Math.random()))
            let Button=document.getElementById(event.currentTarget.id);
            Button.style.backgroundColor="green";
           setTimeout(()=>
           {
               setInformation("")
               Button.style.backgroundColor="";
               setReload(!Reload);
           }, 1000);
        }else{
            setInformation("Your answer is incorrect. Try again.")
            let Button=document.getElementById(event.currentTarget.id);
            Button.style.backgroundColor="red";
            setTimeout(()=>{
                setInformation("")
                Button.style.backgroundColor="";
            }, 1000)
        }
    }
    useEffect(() => {
        setUsingWordsArray(WordsArray)
    }, [WordsArray]);

    useEffect(() => {
        function findNewWord(UsingWordsArray){
            if (UsingWordsArray.length<3)
                UsingWordsArray=WordsArray;
            let RandomWordNumber=Math.round(Math.random()*(UsingWordsArray.length-1));
            let TempUsingWord=UsingWordsArray[RandomWordNumber];
            let WrongAnswersArray=WordsArray.filter(element=>element.partOfSpeach === TempUsingWord.partOfSpeach);
            let WrongAnswers;
            function generateWrongAnswers(Array,GoodAnswer){
                let WrongAnswer1;
                let TempWrongAnswer;
                let Length=WrongAnswersArray.length;

                do {
                    TempWrongAnswer = Math.round(Math.random() * (Length - 1));
                }
                while (TempWrongAnswer === GoodAnswer)
                WrongAnswer1=TempWrongAnswer;

                do{
                    TempWrongAnswer=Math.round(Math.random()*(Length-1));
                }
                while(TempWrongAnswer === WrongAnswer1 && TempWrongAnswer === GoodAnswer)

                return {WrongAnswer1:Array[WrongAnswer1], WrongAnswer2:Array[TempWrongAnswer]}
            }
            console.log(WrongAnswersArray)
            if(WrongAnswersArray.length>2){
                WrongAnswers=generateWrongAnswers(WrongAnswersArray,RandomWordNumber);
            }

            return {
                Word:TempUsingWord,
                WrongAnswer1:WrongAnswers.WrongAnswer1,
                WrongAnswer2:WrongAnswers.WrongAnswer2
            }

        }
        function generateRandomAnswers(Answers, Way){
            let Answer1, Answer2, Answer3;
            let RightAnswer=Math.round(Math.random()*2);
            if(Way==0)
                switch (RightAnswer){
                    case 0:
                        Answer1=Answers.Word.wordInRussian;
                        Answer2=Answers.WrongAnswer1.wordInRussian
                        Answer3=Answers.WrongAnswer2.wordInRussian;
                        break;
                    case 1:
                        Answer2=Answers.Word.wordInRussian;
                        Answer1=Answers.WrongAnswer1.wordInRussian
                        Answer3=Answers.WrongAnswer2.wordInRussian;
                        break;
                    case 2:
                        Answer3=Answers.Word.wordInRussian;
                        Answer2=Answers.WrongAnswer1.wordInRussian
                        Answer1=Answers.WrongAnswer2.wordInRussian;
                        break;
                }
            else
                switch (RightAnswer){
                    case 0:
                        Answer1=Answers.Word.wordInGerman;
                        Answer2=Answers.WrongAnswer1.wordInGerman
                        Answer3=Answers.WrongAnswer2.wordInGerman
                        break;
                    case 1:
                        Answer2=Answers.Word.wordInGerman
                        Answer1=Answers.WrongAnswer1.wordInGerman
                        Answer3=Answers.WrongAnswer2.wordInGerman
                        break;
                    case 2:
                        Answer3=Answers.Word.wordInGerman
                        Answer2=Answers.WrongAnswer1.wordInGerman
                        Answer1=Answers.WrongAnswer2.wordInGerman
                        break;
                }
            return {Answer1:Answer1, Answer2:Answer2, Answer3:Answer3}
        }

        let temp=findNewWord(UsingWordsArray);
        setUsingWord(temp)
        setAnswers(generateRandomAnswers(temp, Way));
        setCorrectAnswer(temp.Word)
        setLoading(true)
    }, [Reload]);


if(Loading)
    return (
        <div className={cl.MainContainer}>
            <div className={cl.TaskText}>
                {Way==0?
                    UsingWord.Word.wordInGerman
                    :
                    UsingWord.Word.wordInRussian
                }
            </div>
            <div className={cl.ButtonsContainer}>
                <button id={Answers.Answer1} onClick={checkAnswer} className={cl.Button}>{Answers.Answer1}</button>
                <br/>
                <button id={Answers.Answer2} onClick={checkAnswer} className={cl.Button}>{Answers.Answer2}</button>
                <br/>
                <button id={Answers.Answer3} onClick={checkAnswer} className={cl.Button}>{Answers.Answer3}</button>
            </div>
           <div className={cl.Information}>
               {Information}
           </div>
        </div>
    );
};

export default WordsTraining;