import axios from "axios";
export default class server_constants{
    static globalURL = 'https://yourprivelege.ru/server/';

    static login = 'QTWma%1N{1hWs%rG}|GK@8g35';
    static psw = '14ygCCEu7m4v~k~}xHKKKkXg4';


    static async get_area(){
        const responce = await axios.get(this.globalURL, {
            params: {
                action: 'get_area',
                login:this.login,
                password:this.psw
            }
        });
        return responce;
    }

}