import React from 'react';
import cl from "./menu.module.css"
const Menu = ({items, setItem, item}) => {
    return (
        <div className={cl.languageMenu}>
            {
                items.map((element, index)=>{
                    let tempClass=cl.menuItem;
                    if(element==item) tempClass=tempClass+" "+cl.active;
                    return (
                        <div id={element} className={tempClass} onClick={e=>setItem(e.currentTarget.id)}>
                            {element}
                        </div>
                    );
                })
            }
        </div>
    );
};

export default Menu;