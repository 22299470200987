import React from 'react';

const EnglishWords = () => {
    return (
        <div>
            English
        </div>
    );
};

export default EnglishWords;